<template>
  <div id="app">
    <router-view v-if="routerAlive"></router-view>
  </div>
</template>

<script>
// import { getStatus } from "./api/machine";
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      routerAlive: true
    };
  },
  methods: {
    reload() {
      this.routerAlive = false;
      this.$nextTick(function() {
        this.routerAlive = true;
      });
    }
  },
  created() {
    // if(!sessionStorage.getItem('username')) {
    //   this.$router.push({ path: "/login" });
    // }
    // getStatus('pdpvsadi',{
    //   TaskType: 'GetAllGroupContact',
    //   DBSync: true
    // }).then(res1 => {
    //   console.log(res1)
    // })
    // getControl(JSON.parse(JSON.stringify({ sandbox: "md5" }))).then(res => {
    //   console.log(res)
    //   getStatus('md5').then(res1 => {
    //     console.log(res1)
    //   })
    // });
  }
};
</script>

<style lang="less">
@font-face {
  font-family: "SYH";
  src: url("./font/font.otf");
  font-weight: normal;
  font-style: normal;
}
.pswp--open {
  z-index: 9999 !important;
}
* {
  padding: 0;
  margin: 0;
  font-family: "SYH";
}
body {
  background: #f7f7f7;
}
#app {
  background: #f7f7f7;
}
.pagination {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #2e5afb !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #2e5afb !important;
  background: #2e5afb !important;
}

.el-button--primary {
  background: #2e5afb !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #2e5afb !important;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #2e5afb !important;
}

// .el-pager li.active {
//   color: #2e5afb !important;
// }
.el-pagination button:hover {
  color: #2e5afb !important;
}
.el-pager li:hover {
  color: #2e5afb !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #2e5afb !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #2e5afb !important;
}
.el-date-table td.today span {
  color: #2e5afb !important;
}
.el-date-table td.available:hover {
  color: #2e5afb !important;
}
.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #2e5afb !important;
}
.el-select-dropdown__item.selected {
  color: #2e5afb !important;
}
.el-textarea__inner:focus {
  border-color: #2e5afb !important;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "*" !important;
  color: red !important;
  margin-right: 4px;
  font-weight: 800;
}
.el-dialog__footer {
  display: flex;
  justify-content: flex-end;
}
.el-button--primary {
  border: none !important;
}
.flex {
  display: flex;
  .el-select {
    width: 250px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.avatar-uploader i {
  line-height: 90px;
}

.title {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  position: relative;
  top: 0.3rem;
  .p1 {
    width: auto;
    background: #2762d6;
    color: #fff;
    padding: 0.15rem 0.3rem;
    font-size: 0.3rem;
    border-radius: 8px;
    font-weight: 600;
  }
  .p2 {
    width: 65%;
    height: 1px;
    background: #2c3a74;
  }
}
</style>

<style>
.el-tooltip__popper {
  max-width: 500px;
}
</style>