import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
// import rem from '@/utils/rem.js'
import 'lib-flexible/flexible.js'
import * as echarts from 'echarts'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

Vue.use(preview)
//局部或全局定义
Vue.prototype.$echarts = echarts
// Vue.config.productionTip = false
// Vue.use(rem);
Vue.use(ElementUI);
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
