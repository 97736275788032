import Vue from 'vue'
import Vuex from 'vuex'
// upInformation

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      roles: sessionStorage.getItem('roles') ? sessionStorage.getItem('roles') : '',
      token: sessionStorage.getItem('token') ? sessionStorage.getItem('token') : ''
    },
    userData: sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : {}
  },
  mutations: {
    login(state,data) {
      state.user.roles = data.roles
      state.user.token = data.token
      sessionStorage.setItem("roles", data.roles); 
      sessionStorage.setItem("token", data.token); 
    }
  },
  actions: {
  },
  modules: {
  }
})
