import axios from "axios";
// import store from "../../store/index";
import router from '../router'
import { MessageBox, Message } from "element-ui";
import setting from './setting'
import store from '../store/index'
let baseURL = setting.URL
// let url2 = "http://192.168.2.18:31007"
const service = axios.create({
  baseURL: baseURL,
  timeout: 20000 // request timeout
});
// request interceptor
service.interceptors.request.use(
  config => {
    config.headers["Authorization"] = store.state.user.token
    // let name = 'JSESSIONID'
    // let cookieValue = ""
    // if (document.cookie && document.cookie !== '') {
    //   let cookies = document.cookie.split(';');
    //   for (let i = 0; i < cookies.length; i++) {
    //     let cookie = cookies[i].trim();
    //     // 判断这个cookie的参数名是不是我们想要的
    //     if (cookie.substring(0, name.length + 1) === (name + '=')) {
    //       cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
    //       break;
    //     }
    //   }
    //   config.headers["X-Access-Token"] = cookieValue
    // }
    return config;
  },
  error => {
    // do something with request error
    // for debug
    return Promise.reject(error);
  }
);

// // response interceptor
service.interceptors.response.use(
  response => {
    const res = response;
    if(!res.code) {
      return res
    }
    if (res.code != null) {
      if (res.code == 500 || res.code == 400) {
        Message({
          message: res.message || "Error",
          type: "error",
          duration: 5 * 1000
        });

        return false;
      }
      
      if(res.code == 401) {
        Message({
          message: '登录状态失效',
          type: "error",
          duration: 5 * 1000
        });
        router.replace({ path: "/login" })
        return false;
      }

      // if the custom code is not 20000, it is judged as an error.
      if (res.code !== 200 ) {
        // Message({
        //   message: res.message || "Error",
        //   type: "success",
        //   duration: 5 * 1000
        // });
        if (res.code === 10010) {
          document.cookie = "JSESSIONID=''";
          router.replace({ path: "/login" })
        }
        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        if (res.code === 510 || res.code === 50012 || res.code === 50014) {
          // to re-login
          MessageBox.confirm(
            "You have been logged out, you can cancel to stay on this page, or log in again",
            "Confirm logout",
            {
              confirmButtonText: "Re-Login",
              cancelButtonText: "Cancel",
              type: "warning"
            }
          ).then(() => {
            // store.dispatch("user/resetToken").then(() => {
            //   location.reload();
            // });
          });
        }
        // return Promise.reject(new Error(res.message || "Error"));
      } else {
        if (res.data) {
          return res;
        } else {
          return res
        }
      }
    }

  },
  error => {
    // console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;

