<template>
  <div class="content" v-loading="loading">
    <scale-box :width="2000" :height="1080" bgc="transparent" :delay="100">
      <div class="header">
        <div class="left"></div>
        <div class="logo">
          <!-- <img src="../../assets/header.png" alt /> -->
          <p>阔 达 能 源</p>
        </div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="top trans-common">
          <!-- 第一个 -->
          <div class="c-l-b">
            <div class="bj">
              <div class="bj-list">
                <div class="icons">
                  <i class="iconfont icon-cloudlightningshandian"></i>
                  <!-- <img src="../../assets/z2.png" alt /> -->
                </div>
                <p class="text">
                  <span class="s1">累计充电度数(度)</span>
                  <span class="s2">{{con.station_info.all_charge_electricity}}</span>
                </p>
              </div>

              <div class="bj-list">
                <div class="icons">
                  <i class="iconfont icon-leijiqushitu"></i>
                  <!-- <img src="../../assets/z2.png" alt /> -->
                </div>
                <p class="text">
                  <span class="s1">累计充电金额(元)</span>
                  <span class="s2">{{con.station_info.all_charge_money}}</span>
                </p>
              </div>

              <div class="bj-list">
                <div class="icons">
                  <i class="iconfont icon-chongdianqi"></i>
                  <!-- <img src="../../assets/z2.png" alt /> -->
                </div>
                <p class="text">
                  <span class="s1">累计充电次数(次)</span>
                  <span class="s2">{{con.station_info.all_charge_number}}</span>
                </p>
              </div>

              <div class="bj-list">
                <div class="icons">
                  <i class="iconfont icon-time"></i>
                  <!-- <img src="../../assets/z2.png" alt /> -->
                </div>
                <p class="text">
                  <span class="s1">累计充电时长(小时)</span>
                  <span class="s2">{{con.station_info.all_charge_time}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="in">
          <div class="left">
            <div class="list">
              <i class="iconfont icon-chongdianzhuangguanli"></i>
              <p>
                <span class="s1">今日充电电量(度)</span>
                <span class="s2">{{con.station_info.today_charge_electricity}}</span>
              </p>
            </div>

            <div class="list">
              <i class="iconfont icon-qianbao1"></i>
              <p>
                <span class="s1">今日充电收入(元)</span>
                <span class="s2">{{con.station_info.today_charge_money}}</span>
              </p>
            </div>
          </div>

          <div class="right">
            <div class="list">
              <div class="title">
                <p class="p1">充电终端监控</p>
                <p class="p2"></p>
              </div>

              <div
                style="display: flex;width: 95%;justify-content: center;align-items: center;justify-content: space-between;height: 90%;margin: auto;"
              >
                <progress-bar
                  text="充电"
                  :sizeOut="68"
                  :index="con.station_info.use_ratio"
                  :total="100"
                />
                <progress-bar
                  text="空闲"
                  :sizeOut="68"
                  :index="con.station_info.free_ratio"
                  :total="100"
                />
                <progress-bar
                  text="异常"
                  :sizeOut="68"
                  :index="con.station_info.error_ratio"
                  :total="100"
                />
              </div>
              <!-- <p>
                <span class="s1">今日充电电量(度)</span>
                <span class="s2">1,300.22</span>
              </p>-->
            </div>

            <div class="list">
              <div class="title">
                <p class="p1">节能减排</p>
                <p class="p2" style="width:73%"></p>
              </div>
              <div class="dlist">
                <p>
                  <span>二氧化碳减排：</span>
                  <span>{{con.station_info.carbon_dioxide_number}} 摩尔</span>
                </p>

                <p>
                  <span>相当于植树量：</span>
                  <span>{{con.station_info.tree_planting_number}} 颗</span>
                </p>
              </div>
              <!-- <p>
                <span class="s1">今日充电收入(元)</span>
                <span class="s2">1,300.22</span>
              </p>-->
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="data">
            <div class="title">
              <p class="p1">日充电汇总(昨日与前一天的对比增长)</p>
              <p class="p2"></p>
            </div>
            <div class="item">
              <div class="list">
                <h2>昨日充电金额（元）</h2>
                <p>{{con.last_station_info.charge_money}}</p>
                <p>
                  <span>环比：</span>
                  <span
                    :style="con.last_station_info.charge_money_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  >{{con.last_station_info.charge_money_ratio}}</span>
                  <span
                    :class="con.last_station_info.charge_money_ratio_status == '-' ? 'iconfont icon-kandie' : 'iconfont icon-gupiao'"
                    :style="con.last_station_info.charge_money_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  ></span>
                </p>
              </div>

              <div class="list">
                <h2>昨日充电时长(小时)</h2>
                <p>{{con.last_station_info.charge_time}}</p>
                <p>
                  <span>环比：</span>
                  <span
                    :style="con.last_station_info.charge_time_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  >{{con.last_station_info.charge_time_ratio}}</span>
                  <span
                    :class="con.last_station_info.charge_time_ratio_status == '-' ? 'iconfont icon-kandie' : 'iconfont icon-gupiao'"
                    :style="con.last_station_info.charge_money_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  ></span>
                </p>
              </div>

              <div class="list">
                <h2>昨日充电度数(度）</h2>
                <p>{{con.last_station_info.charge_electricity}}</p>
                <p>
                  <span>环比：</span>
                  <span
                  :style="con.last_station_info.charge_electricity_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  >{{con.last_station_info.charge_electricity_ratio}}</span>
                  <span 
                    :class="con.last_station_info.charge_electricity_ratio_status == '-' ? 'iconfont icon-kandie' : 'iconfont icon-gupiao'"
                    :style="con.last_station_info.charge_electricity_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  ></span>
                </p>
              </div>

              <div class="list">
                <h2>昨日充电次数(次)</h2>
                <p>{{con.last_station_info.charge_number}}</p>
                <p>
                  <span>环比：</span>
                  <span :style="con.last_station_info.charge_number_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'">{{con.last_station_info.charge_number_ratio}}</span>
                  <span 
                  :class="con.last_station_info.charge_number_ratio_status == '-' ? 'iconfont icon-kandie' : 'iconfont icon-gupiao'"
                  :style="con.last_station_info.charge_number_ratio_status == '-' ? 'color:#9ffe4c' : 'color:red'"
                  >
                  </span>
                </p>
              </div>
            </div>
            <div class="not">
              <p class="not-1">No.1</p>
              <p class="not-2">
                <span class="s1">{{con.order_list[0].station_name}}</span>
                <span class="s2">{{con.order_list[0].charge_electricity}} 度</span>
              </p>
            </div>
          </div>

          <div class="data"></div>
          <!-- -->
        </div>
      </div>
    </scale-box>
  </div>
</template>


<script>
import ScaleBox from "vue2-scale-box";
import { getData } from "../../api/data";
import progressBar from "./components/circle.vue";
// import listData from "./components/listData.vue";
// import vueSeamless from "vue-seamless-scroll";
// import axios from "axios";
export default {
  components: {
    ScaleBox,
    progressBar
    // vueSeamless,
    // listData
  },
  data() {
    return {
      con: []
    };
  },
  created() {
    getData().then(res => {
      res.data.data.station_info.use_ratio = res.data.data.station_info.use_ratio.split(
        "%"
      )[0];
      res.data.data.station_info.free_ratio = res.data.data.station_info.free_ratio.split(
        "%"
      )[0];
      res.data.data.station_info.error_ratio = res.data.data.station_info.error_ratio.split(
        "%"
      )[0];
      res.data.data.last_station_info.charge_money_ratio_status =
        res.data.data.last_station_info.charge_money_ratio.split("%")[0] > 0
          ? "+"
          : "-";
      res.data.data.last_station_info.charge_electricity_ratio_status =
        res.data.data.last_station_info.charge_electricity_ratio.split("%")[0] >
        0
          ? "+"
          : "-";
      res.data.data.last_station_info.charge_time_ratio_status =
        res.data.data.last_station_info.charge_time_ratio.split("%")[0] > 0
          ? "+"
          : "-";
      res.data.data.last_station_info.charge_number_ratio_status =
        res.data.data.last_station_info.charge_number_ratio.split("%")[0] > 0
          ? "+"
          : "-";
      this.con = res.data.data;
    });
  }
};
</script>

<style lang="less" scoped>
// /deep/ .el-dialog {
//    background: url("../../assets/bj.png") no-repeat;
//         background-size: 100% 100%;
//         .content {
//           background: #24284f;
//           color: #fff;
//           .name {
//             color: #fff;
//           }
//         }
// }
.content {
  // width: auto !important;
  // min-width: 1200px;
  min-height: 100vh;
  // padding: 0 !important;
  background: #000739 !important;
}
.header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  background: url("../../assets/db.jpg") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0px;
  // background: red;
  .left {
    width: 40%;
    height: 40%;
    .box {
      width: 70%;
      height: 100%;
      margin: auto;
      display: flex;
      // background: red;
      align-items: center;
      font-weight: 800;
      .nian {
        color: #00d1f0;
        font-size: 0.3rem;
        border-right: 1px solid #00d1f0;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
      }
      .zhou {
        color: #00d1f0;
        font-size: 0.3rem;
        border-right: 1px solid #00d1f0;
        padding-right: 0.5rem;
      }
      .ri {
        color: #fff;
        padding-left: 0.5rem;
        font-size: 0.5rem;
        font-weight: 800;
      }
      a {
        color: #00d1f0;
        font-size: 0.3rem;
        border-right: 1px solid #00d1f0;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        text-decoration: none;
      }
    }
  }
  .logo {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: url("../../assets/11.png") no-repeat;
    // background-size: 100% 100%;
    position: relative;
    p {
      color: #fff;
      font-size: 0.8rem;
      font-weight: 800;
      position: relative;
      top: -0.3rem;
    }
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 10px;
      background: url("../../assets/gh.png") no-repeat;
      background-size: cover;
      left: 0;
      top: 0;
    }
  }
  .right {
    width: 40%;
    height: 40%;
    display: flex;
    justify-content: flex-end;
    .box {
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;
      // background: red;
      align-items: center;

      justify-content: flex-end;
      p {
        color: #00d1f0;
        font-size: 0.3rem;
        border-right: 1px solid #00d1f0;
        padding-right: 0.2rem;
        margin-right: 0.1rem;
        font-weight: 800;
        cursor: pointer;
      }
      a {
        color: #00d1f0;
        font-size: 0.3rem;
        // border-right: 1px solid #00d1f0;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        text-decoration: none;
      }
      // .nian {
      //   color: #00d1f0;
      //   font-size: 0.3rem;
      //   border-right: 1px solid #00d1f0;
      //   padding-right: 0.5rem;
      //   margin-right: 0.5rem;
      // }
      // .zhou {
      //   color: #00d1f0;
      //   font-size: 0.3rem;
      //   border-right: 1px solid #00d1f0;
      //   padding-right: 0.5rem;
      // }
      // .ri {
      //   color: #fff;
      //   padding-left: 0.5rem;
      //   font-size: 0.5rem;
      //   font-weight: 800;
      // }
    }
  }
}
.con {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // margin: auto;
  .top {
    width: 98%;
    margin: auto;
    // height: 300px;
    // background: yellow;
    .c-l-b {
      display: flex;
      justify-content: space-between;
      .bj {
        width: 100%;
        height: 2.8rem;
        // background: url("../../assets/bj.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.6rem;
        padding-bottom: 0.2rem;
        // padding-right: 10px;
        // margin-right: 10px;
        // border-bottom: 1px solid #004988;

        .bj-list {
          width: 23%;
          height: 100%;
          // height: 50px;
          // height: 1rem;
          border-radius: 10px;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          margin: 0.1rem auto;
          background: url("../../assets/bj.png") no-repeat;
          // background: url("../../assets/kuang.png") no-repeat;
          background-size: 100% 100%;
          padding: 10px;
          .text {
            display: flex;
            flex-direction: column;
            color: #fff;
            margin-right: 0.5rem;
            .s1 {
              font-size: 0.4rem;
              margin-bottom: 0.1rem;
              opacity: 0.8;
            }
            .s2 {
              font-size: 0.65rem;
            }
          }
          .icons {
            color: #fff;
            font-weight: 500;
            i {
              font-size: 1.3rem;
              margin-left: 1rem;
              margin-right: 0.5rem;
            }
          }
          // p {
          //   color: #ffffff;
          //   font-size: 0.3rem;
          // }
          // .icons {
          //   display: flex;
          //   align-items: center;
          //   img {
          //     width: 0.9rem;
          //     margin-top: 10px;
          //     margin-right: 10px;
          //   }
          // }
          // h1 {
          //   color: #ffffff;
          //   font-size: 0.4rem;
          // }
        }
      }
      .bt {
        width: 49%;
        height: 2.8rem;
        background: url("../../assets/bj.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #004988;
        // padding-top: 10px;
        // padding-bottom: 10px;
        // padding-right: 10px;
        .b-list {
          width: 33.3%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .img {
            width: 1rem;
            height: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 3rem;
              position: relative;
              top: 0.3rem;
            }
          }
          p {
            font-size: 0.3rem;
            margin-bottom: 0px;
            color: #fff;
            margin-top: 0.6rem;
          }
          span {
            display: block;
            font-weight: 500;
            font-size: 0.4rem;
          }
        }
      }
    }
    .c-l-b-two {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .bj {
        width: 49%;
        height: 8.5rem;
        background: url("../../assets/bj.png") no-repeat;
        background-size: 100% 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        margin-right: 10px;
        border-bottom: 1px solid #004988;
        .bj-nav {
          width: 90%;
          height: 40px;
          line-height: 40px;
          margin: auto;
          background: url("../../assets/j2.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          p {
            color: #fff;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }

      .bt {
        width: 49%;
        height: auto;
        background: url("../../assets/bj.png") no-repeat;
        background-size: 100% 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #004988;

        .bj-nav {
          width: 95%;
          height: 40px;
          line-height: 40px;
          margin: auto;
          background: url("../../assets/j2.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          p {
            color: #fff;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .in {
    width: 98%;
    margin: auto;
    height: 5rem;
    // background: red;
    // margin-left: 0.2rem;
    // margin-right: 0.35rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 49%;
      height: 100%;
      border: 2px solid #2c3a74;
      border-radius: 15px;
      display: flex;
      i {
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
        margin-right: 0.7rem;
      }
      .list {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          display: flex;
          flex-direction: column;
          .s1 {
            color: #fff;
            font-size: 0.43rem;
            margin-bottom: 0.1rem;
          }
          .s2 {
            color: #fff;
            font-size: 0.7rem;
          }
        }
      }
    }
    .right {
      width: 50%;
      height: 100%;
      display: flex;
      .list {
        width: 47.5%;
        // margin-right: 20px;
        height: 100%;
        border: 2px solid #2c3a74;
        margin-left: 0.3rem;
        border-radius: 15px;
      }
      .dlist {
        width: 90%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 1.2rem auto;
        p {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.7rem;
          span {
            font-size: 0.4rem;
            color: #fff;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 4rem;
    margin: 0.45rem auto;
    display: flex;
    .data {
      width: 48%;
      height: 8.5rem;
      margin: auto;
      border: 2px solid #2c3a74;
      border-radius: 20px;
      .item {
        width: 100%;
        height: 50%;
        // border: 2px solid #2c3a74;
        display: flex;
        justify-content: center;
        .list {
          width: 23%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          text-indent: 30px;
          h2 {
            color: #fff;
            font-size: 0.3rem;
            margin-bottom: 0.1rem;
          }
          p {
            font-size: 0.5rem;
            color: #fff;
          }
          span {
            font-size: 0.3rem;
          }

          .iconfont {
            font-weight: 700;
            margin-left: 0.2rem;
          }

          &::after {
            position: absolute;
            content: "";
            width: 7px;
            height: 1.8rem;
            background: #2762d6;
            border-radius: 10px;
            left: 0;
            top: 28%;
            transform: translateX(-28%);
          }
        }
      }
      .not {
        width: 90%;
        height: 1.3rem;
        // background: #fff;
        margin: auto;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #2c3a74;
        padding-top: 0.9rem;
        .not-1 {
          width: 15%;
          height: 1.3rem;
          line-height: 1.3rem;
          background: #f49b2a;
          color: #fff;
          border-radius: 8px 0px 0px 8px;
          text-align: center;
          font-size: 0.6rem;
          font-weight: 800;
        }
        .not-2 {
          width: 85%;
          height: 1.3rem;
          background: #2c3a74;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 0px 8px 8px 0px;
          span {
            font-size: 0.4rem;
            font-weight: 800;
          }
          .s1 {
            position: relative;
            left: 3rem;
          }
          .s2 {
            position: relative;
            right: 1rem;
          }
        }
      }
    }
  }
  // .right {
  //   width: 35%;
  //   height: auto;
  //   // background: yellowgreen;
  //   display: flex;
  //   justify-content: space-between;
  //   .rd {
  //     width: 49%;
  //     height: auto;
  //     display: flex;
  //     flex-direction: column;
  //     .rd-item {
  //       width: 100%;
  //       height: 3rem;
  //       display: flex;
  //       background: url("../../assets/bj.png") no-repeat;
  //       background-size: 100% 100%;
  //       padding-top: 0.1rem;
  //       padding-bottom: 0.1rem;
  //       border-bottom: 1px solid #004988;
  //       .rd-list {
  //         width: 50%;
  //         height: 2.8rem;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         flex-direction: column;
  //         justify-content: center;

  //         img {
  //           width: 1.5rem;
  //           margin-bottom: 0.3rem;
  //           margin-top: 0.3rem;
  //         }
  //         p {
  //           font-size: 0.23rem;
  //           color: #b8e1fe;
  //         }
  //         h3 {
  //           font-size: 0.5rem;
  //           color: #b8e1fe;
  //           font-weight: 800;
  //         }
  //       }
  //     }
  //     .rl-xx {
  //       width: 100%;
  //       background: url("../../assets/bj.png") no-repeat;
  //       margin-top: 0.1rem;
  //       border-bottom: 1px solid #004988;
  //     }
  //   }

  //   .rl {
  //     width: 49%;
  //     // height: 15rem;
  //     // background: url("../../assets/bj.png") no-repeat;
  //     // background-size: 100% 100%;
  //     // border-bottom: 1px solid #004988;
  //     .r-top {
  //       width: 100%;
  //       height: 15rem;
  //       background: url("../../assets/bj.png") no-repeat;
  //       background-size: 100% 100%;
  //       border-bottom: 1px solid #004988;
  //     }

  //     .r-bottom {
  //       width: 100%;
  //       height: 2.45rem;
  //       background: url("../../assets/bj.png") no-repeat;
  //       background-size: 100% 100%;
  //       border-bottom: 1px solid #004988;
  //       margin-top: 0.2rem;
  //       display: flex;
  //       align-items: center;
  //       .rb-list {
  //         width: 48%;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         img {
  //           margin-right: 0.2rem;
  //         }
  //         p {
  //           display: flex;
  //           align-content: center;
  //           flex-direction: column;
  //         }
  //       }
  //     }
  //     // .rl-nav {
  //     //   width: 95%;
  //     //   height: 40px;
  //     //   line-height: 40px;
  //     //   margin: auto;
  //     //   background: url("../../assets/j2.png") no-repeat;
  //     //   background-size: 100% 100%;
  //     //   display: flex;
  //     //   align-items: center;
  //     //   margin-top: 0.2rem;
  //     //   p {
  //     //     color: #fff;
  //     //     font-size: 15px;
  //     //     font-weight: 600;
  //     //   }
  //     // }
  //   }
  // }
  // .rl-nav {
  //   width: 95%;
  //   height: 40px;
  //   line-height: 40px;
  //   margin: auto;
  //   background: url("../../assets/j2.png") no-repeat;
  //   background-size: 100% 100%;
  //   display: flex;
  //   align-items: center;
  //   margin-top: 0.2rem;
  //   p {
  //     color: #fff;
  //     font-size: 15px;
  //     font-weight: 600;
  //   }
  // }
  // .rl-select {
  //   width: 95%;
  //   margin: 0.1rem auto;
  //   display: flex;
  //   .rs-text {
  //     width: 20%;
  //     height: 0.4rem;
  //     line-height: 0.4rem;
  //     background: #092c6b;
  //     border-bottom: 1px solid #8ec0fc;
  //     margin-right: 0.1rem;
  //     text-align: center;
  //     color: #8ec0fc;
  //     font-weight: 500;
  //     cursor: pointer;
  //   }
  //   .rs-text-xz {
  //     background: #2b6494;
  //     color: #fff;
  //     border-bottom: 1px solid #88d8fd;
  //   }
  // }
  // .event-item {
  //   height: 7.6rem;
  //   overflow: hidden;
  //   margin-top: 0.2rem;
  //   overflow-y: scroll;
  //   .event-list {
  //     width: 90%;
  //     margin: auto;
  //     background: #002e69;
  //     border-top: 1px solid #00d1f0;
  //     border-bottom: 1px solid #00d1f0;
  //     margin-top: 0.2rem;
  //     cursor: pointer;
  //     .el-title {
  //       width: 95%;
  //       margin: auto;
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       padding-top: 0.2rem;
  //       p {
  //         height: 0.5rem;
  //         font-size: 0.3rem;
  //         color: #00d1f0;
  //         font-weight: 700;
  //       }
  //       .s1 {
  //         width: 1rem !important;
  //         height: 0.4rem !important;
  //         line-height: 0.4rem !important;
  //         background: #1b3c6d;
  //         color: #7cdea3 !important;
  //         border: 0.9px solid #7cdea3;
  //         text-align: center;
  //       }
  //       .s2 {
  //         width: 1rem !important;
  //         height: 0.4rem !important;
  //         line-height: 0.4rem !important;
  //         background: #24284f;
  //         color: #ef8e3d !important;
  //         border: 0.9px solid #ef8e3d;
  //         text-align: center;
  //       }
  //     }
  //     .el-t {
  //       width: 95%;
  //       height: 0.4rem;
  //       font-size: 0.23rem;
  //       margin: auto;
  //       color: #fff;
  //     }
  //     .el-con {
  //       width: 95%;
  //       margin: auto;
  //       color: #fff;
  //       opacity: 0.8;
  //       font-size: 0.23rem;
  //       padding-bottom: 0.1rem;
  //       line-height: 0.33rem;
  //     }
  //     .el-time {
  //       width: 95%;
  //       margin: auto;
  //       color: #fff;
  //       font-size: 0.23rem;
  //       padding-bottom: 0.2rem;
  //     }
  //   }
  // }

  // .early-list {
  //   width: 90%;
  //   margin: auto;
  //   background: #002e69;
  //   border-top: 1px solid #00d1f0;
  //   border-bottom: 1px solid #00d1f0;
  //   margin-bottom: 0.2rem;
  //   .el-title {
  //     width: 95%;
  //     margin: auto;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     padding-top: 0.2rem;
  //     p {
  //       height: 0.5rem;
  //       font-size: 0.3rem;
  //       color: #00d1f0;
  //       font-weight: 700;
  //     }
  //   }
  //   .el-t {
  //     width: 95%;
  //     height: 0.4rem;
  //     font-size: 0.23rem;
  //     margin: auto;
  //     color: #fff;
  //   }
  //   .el-con {
  //     width: 95%;
  //     margin: auto;
  //     color: #fff;
  //     opacity: 0.8;
  //     font-size: 0.23rem;
  //     padding-bottom: 0.1rem;
  //   }
  //   .el-time {
  //     width: 95%;
  //     margin: auto;
  //     color: #fff;
  //     font-size: 0.23rem;
  //     padding-bottom: 0.2rem;
  //   }
  // }
  // .rx-item {
  //   background: url("../../assets/bj.png") no-repeat;
  //   background-size: 100% 100%;
  //   .rx-header {
  //     width: 95%;
  //     height: 0.8rem;
  //     line-height: 0.8rem;
  //     display: flex;
  //     justify-content: space-between;
  //     background: #002e69;
  //     border-top: 1px solid #00d1f0;
  //     margin: 0.1rem auto;
  //     p {
  //       width: 25%;
  //       height: 1rem;
  //       text-align: center;
  //       font-size: 0.25rem;
  //       color: #00d1f0;
  //       font-weight: 800;
  //     }
  //   }
  //   .rx-list {
  //     width: 95%;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     margin: auto;
  //     cursor: pointer;
  //     p {
  //       width: 30%;
  //       height: 0.67rem;
  //       line-height: 0.67rem;
  //       text-align: center;
  //       color: #fff;
  //       font-size: 0.25rem;
  //     }
  //     .type {
  //       // border: 1px solid #ee4a7d;
  //     }
  //   }
  // }
  // .jiao {
  //   width: 100%;
  //   position: relative;
  //   border-top: 1px solid #004988;
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     width: 15px;
  //     height: 3px;
  //     background: #00d1f0;
  //     left: 0;
  //     top: 0;
  //   }
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     width: 15px;
  //     height: 3px;
  //     background: #00d1f0;
  //     right: 0;
  //     top: 0;
  //   }
  // }
}
</style>