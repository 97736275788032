import Vue from 'vue'
import VueRouter from 'vue-router'
import data from '../views/data/index.vue'
// import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'data',
    component: data,
    children: [{
      // 首页
      path: '/index',
      name: '/index',
      component: resolve => require(['../views/Home.vue'], resolve),
      meta: { requireAuth: true },
    }]
  },{
    path: '/data',
    name: '/data',
    component: resolve => require(['../views/data/index.vue'], resolve),
    meta: { requireAuth: true },
  }, {
    path: '/Login',
    name: 'Login',
    component: resolve => require(['../views/Login.vue'], resolve),
  }
]

// router.beforeEach((to, from, next) => {
//   if (store.state.token) { // getToken用于获取token
//       // 有token处理
//     console.log(2)
//   } else {
//     console.log(1)
//       // 无token处理，跳转到login界面
//     next(`/login?redirect=${to.fullPath}`) 
//   }
// })

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
